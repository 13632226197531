import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import _ from "lodash";

import { Container, Wrapper, Box } from "../components/util";
import { CallToAction } from "../components/site";
import Layout from "../components/layout";
import Seo from "../components/seo";

const PayrollGlossary = (props) => {
  const contentfulData = useStaticQuery(graphql`
    query {
      allContentfulDefinition(sort: { fields: title, order: ASC }) {
        edges {
          node {
            title
            url
            question
          }
        }
      }
    }
  `);

  let definitions = [];

  for (let def of contentfulData.allContentfulDefinition.edges) {
    definitions.push({
      node: {
        question: def.node.question,
        title: def.node.title,
        url: def.node.url,
        title_start_index: def.node.question
          .toLowerCase()
          .indexOf(def.node.title.toLowerCase()),
        title_end_index:
          def.node.question
            .toLowerCase()
            .indexOf(def.node.title.toLowerCase()) + def.node.title.length,
      },
    });
  }

  const grouped_data = _.groupBy(definitions, (definition) => {
    return definition.node.title.substring(0, 1);
  });

  return (
    <Layout>
      <Seo
        title="Payroll Glossary | PayHero"
        description="Become a payroll expert with simplified terms and resources in PayHero's payroll glossary."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper noPaddingBottom>
          <Box className="-textCenter">
            <h1 className="-fontLarge">Payroll Glossary</h1>
            <h4 className="h2 -fontNormal">
              Become a payroll expert with simplified terms and resources.
            </h4>
          </Box>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper maxWidth={900}>
          <Box className="font-bump" stackGap={70}>
            {Object.keys(grouped_data).map((letter, i) => {
              return (
                <Box stackGap={30} key={i}>
                  <p className="h2">{letter}</p>
                  <Box stackGap={30}>
                    {grouped_data[letter].map((def, i) => {
                      return (
                        <h4 key={i}>
                          <Link to={def.node.url}>
                            <span>
                              {def.node.question.slice(
                                0,
                                def.node.title_start_index
                              )}
                            </span>
                            <span>
                              <b>
                                {def.node.question.slice(
                                  def.node.title_start_index,
                                  def.node.title_end_index
                                )}
                              </b>
                            </span>
                            <span>
                              {def.node.question.slice(
                                def.node.title_end_index,
                                def.node.question.length
                              )}
                            </span>
                          </Link>
                        </h4>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Wrapper>
      </Container>
      <CallToAction
        title="Find a payroll expert or get started today"
        buttonText="Start a free PayHero trial today"
        showDirectoryButton
        hideDesc
      />
    </Layout>
  );
};

export default PayrollGlossary;
